export const USER_ROLES = {
    PUBLIC_USER: 'public_user',
    ANONYMOUS_USER: 'anonymous_user',
    CREATE_TENANT: 'create_tenant',
    VIEW_TENANT: 'view_tenant',
    VIEW_USERS: 'view_users',
    CREATE_ADMIN_USER: 'create_admin_user',
    UPDATE_USER_GROUP: 'update_user_group',
    CREATE_TEAM: 'create_team',
    CREATE_ENGAGEMENT: 'create_engagement',
    CREATE_SURVEY: 'create_survey',
    PUBLISH_ENGAGEMENT: 'publish_engagement',
    UNPUBLISH_ENGAGEMENT: 'unpublish_engagement',
    VIEW_ENGAGEMENT: 'view_engagement',
    VIEW_PRIVATE_ENGAGEMENTS: 'view_private_engagements',
    EDIT_ENGAGEMENT: 'edit_engagement',
    REVIEW_COMMENTS: 'review_comments',
    REVIEW_ALL_COMMENTS: 'review_all_comments',
    ACCESS_DASHBOARD: 'access_dashboard',
    VIEW_MEMBERS: 'view_members',
    EDIT_MEMBERS: 'edit_members',
    VIEW_ALL_SURVEYS: 'view_all_surveys',
    EDIT_ALL_SURVEYS: 'edit_all_surveys',
    EDIT_DRAFT_ENGAGEMENT: 'edit_draft_engagement',
    EDIT_SCHEDULED_ENGAGEMENT: 'edit_scheduled_engagement',
    EDIT_UPCOMING_ENGAGEMENT: 'edit_upcoming_engagement',
    EDIT_OPEN_ENGAGEMENT: 'edit_open_engagement',
    EDIT_CLOSED_ENGAGEMENT: 'edit_closed_engagement',
    VIEW_ASSIGNED_ENGAGEMENTS: 'view_assigned_engagements',
    VIEW_APPROVED_COMMENTS: 'view_approved_comments',
    APP_ADMIN: 'app-admin',
    VIEW_SURVEYS: 'view_surveys',
    VIEW_FEEDBACKS: 'view_feedbacks',
    SHOW_ALL_COMMENT_STATUS: 'show_all_comment_status',
    TOGGLE_USER_STATUS: 'toggle_user_status',
    VIEW_ALL_SURVEY_RESULTS: 'view_all_survey_results',
    EXPORT_CAC_FORM_TO_SHEET: 'export_cac_form_to_sheet',
    EXPORT_ALL_CAC_FORM_TO_SHEET: 'export_all_cac_form_to_sheet',
    EXPORT_ALL_TO_CSV: 'export_all_to_csv',
    EXPORT_INTERNAL_COMMENT_SHEET: 'export_internal_comment_sheet',
    EXPORT_PROPONENT_COMMENT_SHEET: 'export_proponent_comment_sheet',
    CREATE_IMAGES: 'create_images',
};
