export const WidgetTabValues = {
    WIDGET_OPTIONS: 'WIDGET_OPTIONS',
    WHO_IS_LISTENING_CREATE: 'WHO_IS_LISTENING_CREATE',
    WHO_IS_LISTENING_FORM: 'WHO_IS_LISTENING_FORM',
    DOCUMENT_FORM: 'DOCUMENT_FORM',
    SUBSCRIBE_FORM: 'SUBSCRIBE_FORM',
    PHASES_FORM: 'PHASES_FORM',
    EVENTS_FORM: 'EVENTS_FORM',
    MAP_FORM: 'MAP_FORM',
    VIDEO_FORM: 'VIDEO_FORM',
};
